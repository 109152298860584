.SideBar {
    /*width: inherit;*/
    /*width: 100%;*/
    /*background-image: linear-gradient(to bottom right, #B8006B, #450920);*/
    background: #000000;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
    position: fixed;
    /* width: 280px; */
    top: 0px;
    bottom: 0px;
    color: white;
    z-index: 100;

    max-width: 280px;
  width: 100%;
    height: 100vh;
}

.sidebar-upper {
    flex-grow: 2;
}

.sidebar-footer {
    margin-bottom: 30px;
}

.sidebar-userinfo{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    padding: 0px 20px;
}

.sidebar-userinfo-container {
    color: black;
    font-family: 'asapregular';
    font-weight: 800;
    width: 200px;
    margin-left: 20px;
    border-radius: 5px;
    display: flex;
    flex-flow: column nowrap;
    text-align: left;
    color: #FFFFFF;
}
.sidebar-userinfo-username {
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}
.sidebar-userinfo-email {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
}

.sidebar-userinfo-logo{
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
}

.sidebar-userinfo-logo img{ 
    width: 18px;
    object-fit: cover;
    cursor: pointer;
}

.sidebar-header {
    margin-top: 25px;
    text-align: center;
}

.sidebar-header-image{
    width: 60%;
}
    .sidebar-title {
        font-family: 'trade-gothic';
        color: #ffffff;
        font-size: 25px;
        line-height: 0px;
        margin-left: 40px;
    }
    .sidebar-ruler{
        margin: 0px auto 0px auto;
        /*width: 92px;*/
        width: 80%;
        border-top: 1px solid #ffffff;
        
    }
    .sidebar-subtitle {
        font-family: 'montserrat-regular';
        font-size: 15px;
        line-height: 0px;
        margin-left: 40px;
        margin-bottom: 20px;
    }

.sidebar-navigation {
    margin-top: 30px;
    display: flex;
    flex-flow: column nowrap;
    
}
    .sidebar-option{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        /* margin-top: 5px; */
        cursor: pointer;
        /* width: 180px; */
        /* padding: 0px 5px 0px 10px; */
        border-radius: 10px;
        /* margin-left: 50px; */
        width: 80%;
        padding: 10px 20px;
        margin: auto;
        box-sizing: border-box;
    }

    .sidebar-option-active {
        /* background-color: rgba(255, 255, 255, 0.2); */
        background-color: #D9D9D9;
        color: #000000;
    }

    .sidebar-option:hover{
        background-color: #D9D9D9;
        color: #000000;
    }

    .sidebar-option-icon{
        width: 25px;
        height:min-content;
    }

    .sidebar-option-title {
        /* margin: 15px auto 15px auto; */
        flex-grow: 1;
        text-align: left;
        margin-left:  10px;
        font-size: 13px;
    }

    .sidebar-navigation a {
        text-decoration: none;
        color: #ffffff;
        font-family: 'montserrat-regular';
    }

   