@font-face {
    font-family: 'libre-bold';
    src: url('../../assets/fonts/LibreBaskerville-Bold.ttf');
}
@font-face {
    font-family: 'libre-italic';
    src: url('../../assets/fonts/LibreBaskerville-Italic.ttf');
}
@font-face {
    font-family: 'montserrat-italic';
    src: url('../../assets/fonts/Montserrat-Italic.ttf');
}
@font-face {
    font-family: 'montserrat-litalic';
    src: url('../../assets/fonts/Montserrat-LightItalic.ttf');
}
@font-face {
    font-family: 'montserrat-regular';
    src: url('../../assets/fonts/Montserrat-Regular.ttf');
}
@font-face {
    font-family: 'trade-gothic';
    src: url('../../assets/fonts/Trade Gothic LT Bold Condensed No. 20.ttf');
}

@font-face {
    font-family: 'oleo';
    src: url('../../assets/fonts/OleoScriptSwashCaps-Bold.ttf');
}

@font-face {
    font-family: 'Caveat-Regular';
    src: url('../../assets/fonts/Caveat-Regular.ttf');
}

@font-face {
    font-family: 'asapregular';
    src: url('../../assets/fonts/asap-variablefont_wght-webfont.woff2') format('woff2'),
         url('../../assets/fonts/asap-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latoregular';
    src: url('../../assets/fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'latobold';
    src: url('../../assets/fonts/lato-bold-webfont.woff2') format('woff2'),
         url('../../assets/fonts/lato-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



.oleo {
    font-family: oleo;
    font-weight: 700;
    font-size: 50px;
}
