.sponsorApp{
    box-sizing: border-box;
    padding: 50px;
}

.sponsors-main__NewSponsor{
    padding: 0px;
}

.newSponsor-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 240px;
    background: #FFFFFF;
    border: 1px solid #352681;
    border-radius: 10px;
    padding: 15px;
    color: #412ea1;
    cursor: pointer;
    margin-left: auto;
    text-decoration: none;
    transition: all 0.08s linear;
}

.newSponsor-button:hover {
    background: #e3ddfc;
}

.newSponsor-buttonTitle {
    font-family: 'trade-gothic';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0;

}

.newSponsor-btnImg {
    width: 30px;
    height: 30px;
    background-size: contain;
}

.sponsors-main__ListSponsor{
    width: 100%;
}