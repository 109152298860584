.verifying-main {
    background-image: linear-gradient(to bottom right, #412ea1, #1a123f);
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    justify-content:center;
    align-items: center;
}

.verifying-checking-session{
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    padding: 30px 30px 30px 30px;
    width: 350px;
}
    .verifying-checking-title {
        color: #543ccc;
        font-family: 'asapregular';
        font-weight: 1000;
    }

    .verifying-user-notfound {
        width: 150px;
    }

.verifying-hidden {
    display: none;
}